<template>
  <div v-if="loaded" style="overflow-x: scroll">
    <div class="px40 p20">
      <h3>Overall</h3>
      <table class="ui celled table">
        <thead>
          <tr>
            <th v-for="h in overall.headers" :key="h[0]">{{ h[0] }}</th>
            <!-- <th>Entry Id</th>
            <th>User</th>
            <th>Files</th>
            <g v-for="(h, k, i) in grouped" :key="k">
              <th>Juror {{i}} Ranking</th>
              <th>Juror {{i}} Marks</th>
              <th>Juror {{i}} Grade</th>
            </g>
            <th>Avg. Ranking</th>
            <th>Avg. Marks</th>
            <th>Avg. Grade</th> -->
          </tr>
        </thead>
        <tr v-for="v in overall.data" :key="v.id">
          <td>{{ v.id }}</td>
          <td>
            <p
              style="font-size: 12px;"
              v-for="u in getUser(v.id)"
              :key="u.id"
              class="pointer"
            >
              {{ u.username }}
            </p>
          </td>
          <td>
            <p
              style="font-size: 12px;"
              v-for="f in v.files"
              @click="$bus.redirect(f.url, true)"
              :key="f.id"
              class="pointer"
            >
              {{ f.name }}
            </p>
          </td>
          <td
            v-for="d in overall.headers.slice(3, overall.headers.length)"
            :key="d[0]"
          >
            {{ v[d[1]] }}
          </td>
        </tr>
      </table>
    </div>
    <div v-for="(v, k) in grouped" :key="k" class="px40 p20">
      <h3>
        Juror: <span>{{ k }}</span>
      </h3>
      <table class="ui celled table">
        <thead>
          <tr>
            <th>Entry Id</th>
            <th>User</th>
            <th>Files</th>
            <th>Ranking</th>
            <th>Marks</th>
            <th>Grade</th>
          </tr>
        </thead>
        <tr v-for="item in v" :key="item">
          <td>{{ item.submissionId }} Hello</td>
          <td>
            <p
              style="font-size: 12px;"
              v-for="u in getUser(item.submissionId)"
              :key="u.id"
              class="pointer"
            >
              {{ u.username }}
            </p>
          </td>
          <td>
            <p
              style="font-size: 12px;"
              v-for="f in item.event_submission
                ? item.event_submission.files
                : []"
              @click="
                window.open(
                  link,
                  '_blank',
                  'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no'
                )
              "
              :key="f.id"
              class="pointer"
            >
              {{ f.name }}
            </p>
          </td>
          <td>{{ item.ranking }}</td>
          <td>{{ item.marks }}</td>
          <td>{{ item.grade }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "jury-results",
  data() {
    return {
      loaded: false,
      grades: ["A", "B", "C", "D", "E", "F", "G"],
      submissions: [],
      eventSubmissions: [],
      event: null
    };
  },
  computed: {
    grouped: function() {
      let obj = {};
      this.submissions.forEach(s => {
        obj[s.jury.email] = obj[s.jury.email] || [];
        obj[s.jury.email].push({
          marks: s.marks,
          grade: s.grade,
          ranking: s.ranking,
          files: s.event_submission.files,
          submissionId: s.event_submission.id
        });
      });

      Object.keys(obj).forEach(key => {
        obj[key] = obj[key]
          .filter(s => s.ranking || s.marks || s.grade)
          .sort((s1, s2) => s2.marks - s1.marks)
          .sort((s1, s2) => s1.ranking - s2.ranking);
      });
      return obj;
    },
    overall: function() {
      let obj = {};
      let headers = [
        ["Entry Id", "id"],
        ["Users", "users"],
        ["Files", "files"]
      ];
      this.submissions.forEach(s => {
        obj[s.event_submission.id] = obj[s.event_submission.id] || [];
        // console.log(s)
        obj[s.event_submission.id].push({
          marks: s.marks,
          grade: s.grade,
          ranking: s.ranking,
          files: s.event_submission.files,
          submissionId: s.event_submission.id
        });
      });

      Object.keys(obj).forEach(key => {
        let avgMarks =
          obj[key]
            .filter(s => s.marks)
            .reduce((sum, val) => sum + (val.marks || 0), 0) / obj[key].length;
        let avgGrade =
          obj[key]
            .filter(s => s.grade)
            .reduce(
              (sum, val) =>
                sum + (val.grade ? this.grades.indexOf(val.grade) : 0),
              0
            ) / obj[key].length;
        let avgRanking =
          obj[key]
            .filter(s => s.ranking)
            .reduce((sum, val) => sum + (val.ranking || 0), 0) /
          obj[key].length;

        obj[key] = {
          id: key,
          files: obj[key][0] ? obj[key][0].event_submission : null,
          marks: avgMarks,
          grade: this.grades[Math.round(avgGrade)],
          ranking: avgRanking
        };

        Object.entries(this.grouped).map((ent, j) => {
          let ix = j + 1;
          let grading = ent[1].find(s => s.submissionId == key);
          if (grading) {
            obj[key]["Jury " + ix + " ranking"] = grading.ranking;
            obj[key]["Jury " + ix + " marks"] = grading.marks;
            obj[key]["Jury " + ix + " grade"] = grading.grade;
          }
        });
      });

      Object.entries(this.grouped).map((ent, j) => {
        let ix = j + 1;
        headers.push(
          ["Jury " + ix + " Ranking", "Jury " + ix + " ranking"],
          ["Jury " + ix + " Marks", "Jury " + ix + " marks"],
          ["Jury " + ix + " Grade", "Jury " + ix + " grade"]
        );
      });
      headers.push(
        ["Avg. Ranking", "ranking"],
        ["Avg. Marks", "marks"],
        ["Avg. Grade", "grade"]
      );
      let vals = Object.values(obj);
      return {
        headers: headers,
        data: vals.sort((s1, s2) => s1.ranking - s2.ranking)
      };
    }
  },
  methods: {
    getUser: function(id) {
      let ev = this.eventSubmissions.find(e => e.id == id);
      return ev ? ev.users : [];
    },
    getSubmissionId: function() {}
  },
  mounted() {
    let headers = {
      headers: {
        Authorization: `Bearer ${this.$bus.token}`
      }
    };
    let params = this.$route.params;
    this.$req
      .get(`events/${params.id}`, headers)
      .then(r => {
        this.event = r.data;
      })
      .catch(e => console.log(e));
    this.$req
      .get(`jury-evaluations?event.id=${params.id}&_limit=-1`, headers)
      .then(r => {
        this.len = this.submissions.length;
        this.submissions = r.data.map((s, i) => {
          s.index = i + 1;
          return s;
        });
        this.$req
          .get(`event-submissions?event.id=${params.id}&_limit=-1`, headers)
          .then(res => {
            this.eventSubmissions = res.data;
            this.loaded = true;
          })
          .catch(e => console.log(e));
      })
      .catch(e => console.log(e));
  }
};
</script>
